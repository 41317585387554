<template>
  <v-container fluid>
    <!-- <div class="text-h4 text-md-h4 text-lg-h4 gris--text">Listado recibos</div> -->
    <!-- <v-typography variant="h4">Busca recibos y aplica pagos.</v-typography> -->
    <v-row no-gutters dense fluid>
      <v-col cols="12" xs="8" sm="6" md="3" lg="3" xl="2">
        <YearPicker v-model="selectedYear" />
      </v-col>
      <!-- <v-col xs="8" sm="6" md="3" lg="3" xl="2">
        <v-text-field v-model="Busqueda"   label="Busqueda"
        @input="onInputBusqueda" outlined dense></v-text-field>
      </v-col> -->

      <v-col xs="4" sm="4" md="2" lg="1" xl="1" class="text-center">
        <v-btn color="azul_med" @click.native="onClickFunction" dark elevation="0">Buscar</v-btn>
      </v-col>
      <v-col xs="4" sm="4" md="2" lg="1" xl="1" class="text-center">

        <v-btn color="azulclaro" @click.native="onClickDescargar" dark elevation="0"> <v-icon left>mdi-printer</v-icon>
          Imprimir</v-btn>
      </v-col>
      <v-col xs="4" sm="4" md="3" lg="4" xl="4" class="text-center">
      </v-col>
      <v-col no-gutters class="d-flex justify-end">
        <!-- <v-chip  class="ma-1" color="azul_med" outlined label>
          Total: {{ $formatCurrency(this.total) }}
      </v-chip>
      <v-chip class="ma-1" color="azul_med" outlined label>
          Aplicados: {{ $formatCurrency(this.totalPagados) }}
      </v-chip> -->
        <v-col no-gutters>
          Recibos aplicados: <strong>{{ this.pagados }} </strong> de <strong> {{ this.num_recibos }} </strong>
          <v-progress-circular :value="Porciento" :size="50" :width="5" color="green" rotate="-90">
            {{ Porciento }}%
          </v-progress-circular>
        </v-col>
        <!-- <v-chip class="ma-1" color="grey" outlined label>
          Faltante: {{ $formatCurrency(this.totalPendientes) }}
      </v-chip> -->
      </v-col>
    </v-row>
    <div class="cont-bord-gris">
      <v-row no-gutters justify="end" dense>
        <v-col>
          <v-list-item-subtitle>
            <v-chip dense class="ma-1" color="azul_med" outlined label>
              Pagados: {{ this.pagados
              }}
            </v-chip>
            <v-chip dense color="red" outlined dark label>
              Impago: {{ this.impago
              }}
            </v-chip>
            <v-chip dense class="ma-1" color="grey" outlined label>
              Total: {{ this.num_recibos
              }}
            </v-chip>
          </v-list-item-subtitle>
        </v-col>
        <!-- <v-col class="text-sm-h6  red--text">
          <v-list-item-subtitle>Recibos Impago: {{ this.impago }}</v-list-item-subtitle>
        </v-col>
        <v-col class="text-sm-h6 green--text">
          <v-list-item-subtitle>Recibos pagados: {{ this.pagados }}</v-list-item-subtitle>
        </v-col>-->
        <v-col xs="6" sm="6" md="4" lg="5" xl="4">
          <v-text-field clearable v-model="search" append-icon="mdi-magnify" label="Buscar en tabla" single-line
            hide-details outlined dense></v-text-field>
        </v-col>
      </v-row>
      <!-- mobile-breakpoint="600"
            fixed-header
              height="400px" -->
      <!-- show-expand :expanded.sync="expanded" :item-class="getRowClass" :items-per-page="-1" fixed-header height="60vh :items="pagos"" -->
      <v-data-table group-by="grupo" :items="pagosModificados" :items-per-page="-1" fixed-header height="60vh"   :custom-filter="customFilter"
        calculate-widths :headers="headers" dense :search="search" item-key="idpago" class="custom-data-table"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus',
          'items-per-page-text': 'Filas por página'
        }" no-data-text="No hay datos disponibles" no-results-text="No se encontraron resultados"
        @click:row="selectRow" :item-class="getRowClass">

        <template v-slot:item="{ item }">
          <tr @click="selectRow(item)" :class="{ 'selected-row': selectedRow && selectedRow === item.idpago }">

            <template>
              <td>
                <small>{{ item.idpago }}</small>
                <!-- <br/> <v-icon color="red" size="24">mdi-file-pdf-box</v-icon> | <v-icon color="blue" size="24">mdi-file-pdf-box</v-icon> -->
              </td>
            </template>
            <!-- <td :style="{ background: getColor(item.contrato) }">
              <span v-if="item.estado === '1'" class="estatus-activo">Activa</span>
              <span v-if="item.estado === '2'" class="estatus-expirado">Suspendida</span><br />
              <strong>{{ item.contrato }}</strong>
            </td> -->
            <template>
              <td>
                <span style="font-size: 12px;">{{ item.contratante }}</span>
              </td>
            </template>
            <template>
              <td>
                <span style="font-size: 12px;"> {{ item.nombretitular }}</span>
              </td>
            </template>
            <template>
              <td>
                <span> <strong>{{ item.recibo }}</strong> de <strong>{{ item.recibos }}</strong></span>
              </td>
            </template>
            <template>
              <td v-if="item.pagado === 0">
                <span v-if="item.Diferencia <= 1 && item.Diferencia > 0">{{ item.Diferencia }}
                  días</span>
                <span v-if="item.Diferencia > 1" class="estatus-retardo">{{ item.Diferencia }}
                  días</span>
                <span v-if="item.Diferencia < 0" style="color: olivedrab;">A TIEMPO</span>
              </td>
              <td v-if="item.pagado === 1" :style="{ background: getColor(item.contrato) }">
                <span>
                  <v-btn v-if="item.pagado === 1 && item.Factura !== null" icon
                    :href="'https://api.medicover.mx/api/verfactura?membresia=' + item.Factura + '.pdf'"
                    target="_blank">
                    <v-icon size="20" color="red">mdi-file-pdf-box</v-icon>
                  </v-btn>
                  <v-btn v-if="item.pagado === 1 && item.Factura !== null" icon
                    :href="'https://api.medicover.mx/api/verfactura?membresia=' + item.Factura + '.xml'"
                    target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                      <polyline points="14 2 14 8 20 8" />
                      <text x="6" y="19" font-size="6" fill="currentColor">&lt;XML&gt;</text>
                    </svg>
                  </v-btn>
                  <v-btn text dense v-if="item.pagado === 1 && item.Factura === null" @click="facturarPago(item)"
                    class="text--secondary">
                    FACTURAR
                  </v-btn>
                </span>
              </td>
            </template>
            <template>
              <td class="text-center" v-if="item.pagado === 0">
                IMPAGO
              </td>
              <td v-else dark class="elemento-pagado text-center">
                <strong>PAGADO</strong>
                <div class="small-chip">
                  {{ item.FormaPago }}
                </div>
              </td>
            </template>
            <template>
              <td>
                <span class="creado-style">{{ item.DatePagoFormat }}</span>
              </td>
            </template>
            <template>
              <td>
                <span class="creado-style"> {{ item.FecPagoFormat }}</span>

              </td>
            </template>
            <template>
              <td class="text-end">
                <span class="estatus-activo">{{ $formatCurrency(item.pago) }}</span>
              </td>
            </template>
            <template>
              <v-menu open-on-hover offset-z left :close-on-content-click="true">
                <template v-slot:activator="{ on, attrs }">
                  <td><v-btn @click="selectRow(item)"
                      :class="{ 'selected-row': selectedRow && selectedRow === item.idpago }" red icon v-bind="attrs"
                      v-on="on">
                      <v-icon size="28">mdi-dots-vertical-circle-outline</v-icon>
                    </v-btn></td>
                </template>
                <v-list>
                  <v-list-item :disabled="!(item.pagado == '0')" @click="openModalPago(item)">
                    <v-list-item-icon>
                      <v-icon>mdi-cash-register</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> APLICA PAGO</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="verPagos(item.contrato)">
                    <v-list-item-icon>
                      <v-icon>mdi-list-box-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> HISTORIAL RECIBOS</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="$verRecibo(item.idpago)">
                    <v-list-item-icon>
                      <v-icon>mdi-eye-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> VER RECIBO</v-list-item-title>
                  </v-list-item>
                  <v-list-item :disabled="false">
                    <email-recibo-modal :ReciboId="item.idpago" :Membresia="item.contrato"
                      :Recibo="`${item.recibo} de ${item.recibos}`" :Titular="item.nombretitular"
                      :Monto="$formatCurrency(item.pago)" :propEmail="item.Email">
                    </email-recibo-modal>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </tr>
        </template>

      </v-data-table>
      <modal-pago v-model="dialogPagoShow" :item="selectedPago" @save="aplicaPagoActiva"></modal-pago>
      <list-pagos :isOpen.sync="isModLisPagos" :Membresia="this.MembresiaSelec"></list-pagos>
    </div>
  </v-container>
</template>
<script>

import ApiBase from '@/functions/axios/axiosNetBase'
import YearPicker from '@/components/AnioComponent.vue';
import EmailReciboModal from '@/components/Pagos/EmailReciboComponent.vue'
import ModalPago from '@/components/Pagos/PagoComponent.vue'
import EndPointNet from '@/functions/axios/axios-bearer'


export default {
  name: 'InicioAgente',
  components: {
    YearPicker,
    EmailReciboModal,
    ListPagos: () => import('@/components/ListPagoComponent.vue'),
    ModalPago
  },
  watch: {
    "$route.params.mem"(newVal) {
      console.log(newVal)
      if (newVal !== null && newVal !== undefined) {
        this.search = newVal;
      }
    },
    selectedYear(val) {
      if (val) {
        this.Busqueda = null
      }
    }
  },
  computed: {
    pagosModificados() {
      return this.pagos.map(pago => ({
        ...pago,
        grupo: `${pago.contrato} - ${pago.estado === '1' || pago.estado === 1 ? 'ACTIVA' : 'SUSPENDIDA'} -  ${pago.NomColectivo !== null ? '' + pago.NomColectivo : ''}`
      }));
    },
    duplicateColors() {
      const counts = {};
      const colors = {};
      //const colorPalette = ["#FFEB3B", "#FFCDD2", "#C8E6C9", "#BBDEFB", "#FFCCBC", "#D1C4E9"]; 
      const colorPalette = [
        "#FEF9F9", // Rosa pétalo suave
        "#FFFDF5", // Crema marfil
        "#FFFAF0", // Blanco durazno
        "#FFFCF2", // Beige nube
        "#FDF7FF", // Lavanda brisa
        "#F5F2FF", // Lila etéreo
        "#F0F9FF", // Azul celeste hielo
        "#EFFFFA", // Verde agua cristalino
        "#F6FFF0", // Verde lima neblina
        "#FFFFF0", // Amarillo algodón
        "#F8FFF8", // Verde menta nevada
        "#FCFFF4", // Amarillo pastel apagado
        "#FAFEFF", // Azul polar
        "#F4F8FF", // Azul neón apagado
        "#F0F0F0", // Gris perla claro
        "#FFFFFF", // Blanco puro
      ];

      let colorIndex = 0;

      this.pagos.forEach((item) => {
        counts[item.contrato] = (counts[item.contrato] || 0) + 1;
      });

      Object.keys(counts).forEach((key) => {
        if (counts[key] > 1) {
          colors[key] = colorPalette[colorIndex % colorPalette.length]; // Asigna colores en ciclo
          colorIndex++;
        }
      });
      return colors;
    }
  },
  mounted() {
    this.getPagosPendientes()
    this.search = this.$route.params.mem || null;
  },
  data: () => ({
    bucketFormatos: process.env.VUE_APP_BUCKETFORMATOS,
    dialogPagoShow: false,
    selectedPago: {},
    Porciento: 0,
    snackbar: true,
    vertical: true,
    selectedRow: null,
    textAlert: '',
    NumRecibosSelec: null,
    MembresiaSelec: null,
    isModLisPagos: false,
    Busqueda: null,
    reciboSeleccionado: null,
    selectedYear: new Date().toISOString().substr(0, 7), // new Date().getFullYear().toString(), or null
    search: '',
    pagos: [],
    expanded: [],
    total: 0,
    totalPagados: 0,
    totalPendientes: 0,
    headers: [
      { text: 'Folio', value: 'idpago', width: '5%', sortable: false },
      //{ text: 'Contrato', value: 'contrato', width: '10%', sortable: true },
      //{ text: "Contrato", value: "contrato", align: "d-none" }, // Columna oculta
      { text: 'Contratante', value: 'contratante', width: '15%', sortable: false },
      { text: 'Titular', value: 'nombretitular', width: '15%', sortable: false },
      //{ text: 'Agente', value: 'agente', sortable: true },
      { text: 'Recibo', value: 'recibo', width: '10%', sortable: false },
      { text: 'Retardo/factura', value: 'Diferencia', width: '10%', sortable: false },
      { text: 'Estatus', value: 'pagado', width: '10%', sortable: true },
      { text: 'FechaPago', value: 'DatePagoFormat', width: '10%', sortable: false },
      { text: 'Aplicado', value: 'FecPagoFormat', width: '10%', sortable: false },
      { text: 'Monto', value: 'pago', width: '5%', sortable: false, align: 'end' },
      { text: 'Opciones', value: 'ops', width: '5%', sortable: false, align: 'center' },
    ],

    num_recibos: 0,
    pagados: 0,
    impago: 0
  }),
  methods: {
    customFilter(value, search, item) {
    return item.contrato.toLowerCase().includes(search.toLowerCase()) || 
           item.contratante.toLowerCase().includes(search.toLowerCase()) ||
           item.pago.toString().includes(search);
  },
    getColor(contrato) {
      //const color = this.duplicateColors[contrato];
      //return color ? `3px solid ${color}` : "none";
      return this.duplicateColors[contrato] || "transparent"; // Si no es duplicado, fondo transparente
    },
    openModalPago(item) {
      this.selectedPago = { ...item };
      console.log(this.selectedPago)
      this.dialogPagoShow = true;
    },
    facturarPago(item) {
      console.log(item)
      this.$loading(true)
      //this.dialogPagoShow = true;

      EndPointNet(
        'Payment/UpdateFactura',
        'POST',
        {
          Folio: item.idpago,
          MetodoPago: ""
        }
      ).then((response) => {
        if (response.status === 200) {
          this.dialogPagoShow = false;
          this.$swal.close();
          this.$loading(false)
          if (response.data) {
            item.Factura = response.data.factura;

            const index = this.pagos.findIndex(p => p.idpago === item.idpago);
            if (index !== -1) this.$set(this.pagos, index, { ...item });
            this.$swal({
              toast: true,
              position: 'top-start',
              title: 'Correcto',
              text: response.data.message,
              icon: 'success',
              confirmButtonText: 'Cerrar',
              showCloseButton: false,
              showConfirmButton: true,
              timer: 4000,
              width: '40%',  // Más ancho
              backdrop: false, // Evita que la alerta haga scroll al inicio
            })
          }
        }
        if (response.status === 409) {
          this.dialogPagoShow = false;
          this.$swal.close();
          this.$swal({
            position: '',
            // title: 'Valida',
            //text: 'Se genero un error',
            html: `No se puede facturar </b> ${response.data}.`,
            icon: 'error',
            confirmButtonText: 'Cerrar',
            showCloseButton: true,
            showConfirmButton: true,
            backdrop: false, // Evita que la alerta haga scroll al inicio
            width: '400px',
            //timer: 1500,
          })
        }
        this.dialogPagoShow = false;
      })
        .catch(() => {
          this.$loading(false)
          this.$swal.close();
          this.$swal({
            position: '',
            // title: 'Valida',
            //text: 'Se genero un error',
            html: `Hubo un error no esperado <b>intenta más tarde</b> .`,
            icon: 'error',
            confirmButtonText: 'Cerrar',
            showCloseButton: true,
            showConfirmButton: true,
            backdrop: false, // Evita que la alerta haga scroll al inicio
            width: '400px',
            //timer: 1500,
          })
        });
    },
    aplicaPagoActiva(item) {
      this.$loading(true)
      //this.dialogPagoShow = true;

      EndPointNet(
        'Payment',
        'POST',
        {
          Folio: item.idpago,
          MetodoPago: item.CodPago,
          Detalle: item.Referencia
        }
      ).then((response) => {
        if (response.status === 200) {
          const fechaHoy = new Date().toLocaleDateString("es-ES", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          }).replace(/\//g, "-");

          this.dialogPagoShow = false;
          this.$loading(false)
          if (response.data) {
            console.log(response.data)
            item.pagado = 1
            item.FecPagoFormat = fechaHoy
            item.Factura = response.data.factura;
            /*
            const index = this.pagos.findIndex(p => p.idpago === item.idpago);
            if (index !== -1) {
              this.$set(this.pagos, index, { ...item, highlight: true });

                // Espera a que Vue actualice el DOM
                this.$nextTick(() => {
                    const fila = this.$refs[`fila-${item.idpago}`]?.[0]; // Acceder al elemento
                    if (fila) {
                        fila.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                });

                // Quitar el resaltado después de unos segundos
                setTimeout(() => {
                    this.$set(this.pagos, index, { ...this.pagos[index], highlight: false });
                }, 3000);
            }
            */
            const index = this.pagos.findIndex(p => p.idpago === item.idpago);
            if (index !== -1) this.$set(this.pagos, index, { ...item });
            this.$swal.close();
            this.$swal({
              toast: true,
              position: 'top-start',
              title: 'Correcto',
              text: response.data.message,
              icon: 'success',
              confirmButtonText: 'Cerrar',
              showCloseButton: false,
              showConfirmButton: true,
              timer: 4000,
              width: '40%',  // Más ancho
              backdrop: false, // Evita que la alerta haga scroll al inicio
            })

          }
        }
        if (response.status === 409) {
          this.$swal.close();
          this.dialogPagoShow = false;
          this.$swal({
            position: '',
            // title: 'Valida',
            //text: 'Se genero un error',
            html: `No se puede aplicar el pago ya que </b> ${response.data}.`,
            icon: 'error',
            confirmButtonText: 'Cerrar',
            showCloseButton: true,
            showConfirmButton: true,
            //timer: 1500,
            width: '400px',
            backdrop: false, // Evita que la alerta haga scroll al inicio
          })
        }
        this.dialogPagoShow = false;
      })
        .catch(() => {
          this.$swal.close();
          this.$loading(false)
          this.$swal({
            position: '',
            // title: 'Valida',
            //text: 'Se genero un error',
            html: `Hubo un error no esperado <b>intenta más tarde</b> .`,
            icon: 'error',
            confirmButtonText: 'Cerrar',
            showCloseButton: true,
            showConfirmButton: true,
            //timer: 1500,
            width: '400px',
            backdrop: false, // Evita que la alerta haga scroll al inicio
          })
        });
    },
    registraPago(pago) {
      console.log(pago)
      pago.pagado = 1
      const index = this.pagos.findIndex(p => p.idpago === pago.idpago);
      if (index !== -1) this.$set(this.pagos, index, { ...pago });
    },
    Descargar() {
      this.$loading(true)
      var urltarjet = `${process.env.VUE_APP_BUCKETFORMATOS}Pagos/reportePagos?qry=${this.selectedYear}`
      var newTab = window.open(urltarjet);
      if (newTab) {
        newTab.focus();
        this.$loading(false)
      }
    },
    selectRow(item) {
      this.selectedRow = item.idpago;
    },
    getRowClass(item) {
      return this.selectedRow && this.selectedRow === item.idpago ? 'selected-row' : '';
      //return item.pagado === 1 ? 'pagado-row' : 'impago-row';
    },
    verPagos(data) {
      console.log(data)
      this.MembresiaSelec = data
      this.isModLisPagos = true
    },
    editItem(item) {
      console.log(item)
    },
    deleteItem(item) {
      console.log(item)
    },
    onCleardate() {
      this.selectedYear = null
      this.getPagosPendientes()
    },
    onClickFunction() {
      this.getPagosPendientes()
    },
    verSoloPendientes() {
      this.selectedYear = null
      this.getPagosPendientes()
    },
    getPagosPendientes() {
      this.$loading(true)
      var url = this.selectedYear ? `getCobranza?qry=${this.selectedYear}` : `getCobranza`
      ApiBase(
        url,
        'GET'
      ).then((response) => {
        if (response.status === 200) {
          if (response.data) {
            this.pagos = response.data
            this.num_recibos = this.pagos.length;
            this.impago = this.pagos.filter(i => i.pagado === 0).length
            this.pagados = this.pagos.filter(i => i.pagado === 1).length
            this.Porciento = ((this.pagados / this.num_recibos) * 100).toFixed(0);
            this.total = this.pagos.reduce((total, pago) => total + pago.pago, 0);
            this.totalPagados = this.pagos.filter(pago => pago.pagado === 1)
              .reduce((total, pago) => total + pago.pago, 0);
            this.totalPendientes = this.pagos.filter(pago => pago.pagado === 0)
              .reduce((total, pago) => total + pago.pago, 0);
            this.$loading(false)
          }
        }
      })
        .catch(() => {
          this.$loading(false)
          this.$swal({
            position: '',
            // title: 'Valida',
            //text: 'Se genero un error',
            html: `Ha ocurrido un error inesperado, <b>intenta mas tarde</b> .`,
            icon: 'error',
            confirmButtonText: 'Cerrar',
            showCloseButton: true,
            showConfirmButton: true,
            //timer: 1500,
          })
        });
    },
    registrarPago(item) {
      console.log(item)
      this.PagoSelect.Membresia = item.contrato
      this.PagoSelect.PagoNum = item.recibo + ' de ' + item.recibos
      this.PagoSelect.Folio = item.idpago
    },
    onInputBusqueda(value) {
      this.selectedYear = null,
        this.Busqueda = value
    }
  }
}
</script>
<style scoped>
.creado-style {
  font-weight: bold;
  color: #696969;
  font-size: 12px;
}

.impago-row .v-data-table__wrapper tbody tr {
  background-color: rgb(220, 239, 220) !important;
}

.custom-data-table .pagado-row {
  background-color: rgb(220, 239, 220) !important;
}

.custom-data-table .impago-row {
  background-color: rgb(255, 255, 25) !important;
}

.custom-data-table .older-row {
  background-color: #ffe4e1;
  /* Color para filas con edad > 30 */
}

.custom-data-table .younger-row {
  background-color: #e0ffff;
  /* Color para filas con edad <= 30 */
}

.elemento-pagado {
  background-color: #C8E6C9;
}

.small-chip {
  font-size: 10px;
  color: #575757;
}

.selected-row {
  background-color: #a0d0f4 !important;
  /* Color de fondo para la fila seleccionada */
}
</style>