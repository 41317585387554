<template>
  <v-container fluid>
    <!-- Diálogo citas médicas-->
    <v-dialog v-model="isModalCita" max-width="90%" persistent>
      <v-card>
        <v-card-title>
          <span class="text-h5">Pases médicos</span>
        </v-card-title>
        <v-card-text>
          Elige la <strong>especialidad médica,</strong> <strong>médico</strong> y <strong>beneficiario</strong> que
          acudirá a la cita. Número de citas disponibles: <strong>{{ this.MembresiaSelecNumCitas }}</strong>
          Estatus: <strong>{{ this.MembresiaSelecEstatus }}</strong>
          <v-row v-show="this.MembresiaSelecNumCitas > 0 && this.MembresiaSelecEstatus === 'ACTIVO'">
            <v-col cols="6" xs="6" sm="3" md="6" lg="3" xl="3">
              <especialidades-component @value-changed="updateEsp"></especialidades-component>
            </v-col>
            <v-col cols="6" xs="6" sm="3" md="4" lg="3" xl="3">
              <medicos-component v-show="this.especialidad" @value-changed="updateMed"
                :especialidadValue="this.especialidad"></medicos-component>
            </v-col>
            <v-col cols="6" xs="6" sm="3" md="4" lg="3" xl="3">
              <benediciados-component v-show="this.medico" @value-changed="updateBen"
                :initialmembresiaValue="this.MembresiaSelec"></benediciados-component>
            </v-col>
            <v-col cols="6" xs="6" sm="3" md="4" lg="3" xl="3">
              <v-btn color="azul_med" @click.native="crearCita" dark>+
                GENERAR PASE </v-btn>
            </v-col>
          </v-row>
          <citas-medicas :Recargar = "this.RecargaListCitas" :Membresia="this.MembresiaSelec"></citas-medicas>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- Botón para cerrar el diálogo -->
          <v-btn text @click="cerrar">
            CERRAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <p class="font-weight-regular">
      Listado de <strong>membresías</strong> puedes agrupar por colectividad y/o Agente.
    </p> -->
    <v-card outlined>

      <!-- <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">
          OVERLINE
        </div>
        <v-list-item-title class="text-h5 mb-1">
          Headline 5
        </v-list-item-title>
        <v-list-item-subtitle>      Listado de <strong>membresías</strong>, puedes agrupar por colectividad y/o Agente.</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>  -->

      <!-- <v-card-actions>
      <v-btn
        outlined
        rounded
        text
      >
        Button
      </v-btn>
    </v-card-actions> -->
      <v-card-subtitle>Listado de membresías puedes <strong>buscar</strong> por número de membresía, nombre titular,
        agente y colectividad.</v-card-subtitle>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field clearable class="col" v-model="Nombremem" label="Titular ó membresía" single-line hide-details
              outlined dense></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <list-agentes @agent-change="changeAgent"></list-agentes>
          </v-col>
          <v-col cols="12" md="4">
            <list-colectivos :propAdmin="true" @colectivo-change="changeColectividad"></list-colectivos>
            <v-btn color="azul_med" v-show="verOptDownloadCole" @click.native="DescargaZip" dark small>Descargar
              tarjetas
              .zip</v-btn>
          </v-col>
          <v-col cols="12" md="2">
            <v-btn color="azulfuerte" @click.native="Buscar" dark>Buscar</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-container>

      <v-row justify="end">
        <v-col sm="12" md="4" lg="4" xl="4">
          <v-text-field class="col" v-model="search" append-icon="mdi-magnify" label="Buscar en resultados" single-line
            hide-details outlined dense @change="comp_change()"></v-text-field>
        </v-col>
      </v-row>
      <v-data-table :items-per-page="5" calculate-widths :headers="headers" :items="contratos" item-key="GUID"
        :search="search" :group-by="['Agente']" no-data-text="No hay datos" :footer-props="customFooterProps">
        <template v-slot:[`item.index`]="{ index }">
          <td>{{ index + 1 }}</td>
        </template>
        <template v-slot:[`item.Plan`]="{ item }">
          <div class="card">
            <div class="card-header">{{ item.Plan }} </div>
            <div class="card-body">
              Citas restantes: {{ item.NumCitas }} <br />
              Urgencias: {{ $formatCurrency(item.MontoUrgencias) }} <br />
              Coberturas: {{ item.Coberturas }} <br />
              Tipo pago: {{ item.TipoPago }} <br />
            </div>
          </div>
        </template>
        <template v-slot:[`item.ContNumTel`]="{ item }">
          <td><small><a v-if="item.ContNumTel" @click="$copyText(item.ContNumTel)">{{ item.ContNumTel }} </a><br />
              <a @click="$copyText(item.ContEmail)" v-if="item.ContEmail">{{ item.ContEmail
                }}</a></small></td>
        </template>
        <template v-slot:[`item.GUID`]="{ item }">
          <td>{{ item.GUID }}</td>
        </template>
        <!-- @click:row="handleRowClick" -->
        <template v-slot:[`item.Estatus`]="{ item }">
          <td><span v-if="item.Estatus === 'ACTIVO'" class="estatus-activo">{{ item.Estatus }}</span>
            <span v-if="item.Estatus === 'SUSPENDIDO'" class="estatus-impago">{{ item.Estatus }}</span>
            <span v-if="item.Estatus === 'BAJA'" class="estatus-expirado">{{ item.Estatus }}</span>
          </td>
        </template>
        <template v-slot:[`item.MontoUrgencias`]="{ item }">
          <td>
            <span v-if="item.MontoUrgencias === 0" class="urgencias0">
              {{ $formatCurrency(item.MontoUrgencias) }}
            </span>
            <span v-else>
              {{ $formatCurrency(item.MontoUrgencias) }}
            </span>
          </td>
        </template>
        <template v-slot:[`item.FecIniVig`]="{ item }">
          <td>{{ $formatDate(item.FecIniVig) }}</td>
        </template>
        <template v-slot:[`item.FecAlta`]="{ item }">
          <td>{{ $formatDate(item.FecAlta) }}</td>
        </template>
        <template v-slot:[`item.DatosActualizadosPortal`]="{ item }">
          <td> <span v-if="item.DatosActualizadosPortal"> {{ $formatDate(item.DatosActualizadosPortal) }}</span>
            <span v-else><v-icon dense color="red darken-2">
                mdi-close
              </v-icon></span>
          </td>
        </template>
        <template v-slot:[`item.Titular`]="{ item }">
          <td>
            {{ item.Titular }}
            <br />
            <small><a v-if="item.ContNumTel" @click="$copyText(item.ContNumTel)">{{ item.ContNumTel }} </a><br />
              <a @click="$copyText(item.ContEmail)" v-if="item.ContEmail">{{ item.ContEmail
                }}</a></small>
          </td>
        </template>
        <template v-slot:[`item.Contratante`]="{ item }">
          <td>
            <v-chip v-if="item.Colectividad"
              style="max-width: 160px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" class="ma-1"
              label text-color="greey" x-small>
              <v-icon left>
                mdi-label
              </v-icon>
              {{ item.Colectividad }}
            </v-chip><br />
            <small
              v-if="(item.Contratante.replace(/\s+/g, '').toUpperCase() || '' ) != (item.Titular.replace(/\s+/g, '').toUpperCase() || '')">
              {{ item.Contratante }}
            </small>
          </td>
        </template>
        <template v-slot:[`item.Colectividad`]="{ item }">
          <td>
            <small>{{ item.Colectividad }}</small>
          </td>
        </template>
        <template v-slot:[`item.Membresia`]="{ item }">
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn red icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="modalCita(item)">
                <v-list-item-title> Pases médicos</v-list-item-title>
              </v-list-item>
              <v-list-item :disabled="(item.Estatus === 'BAJA')" @click="editItem(item)">
                <v-list-item-title>
                  <ButtonMembresia :propObjTarjet="item"></ButtonMembresia>
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="verPagos(item.GUID, item.TotRecibos)">
                <v-list-item-title> Ver recibos</v-list-item-title>
              </v-list-item>
              <v-list-item @click="verBeneficiarios(item.GUID)">
                <v-list-item-title> Ver Beneficiarios</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-container>

    <list-pagos :isOpen.sync="isModLisPagos" :Membresia="this.MembresiaSelec"
      :NumRecibos="this.NumRecibosSelec"></list-pagos>
    <list-beneficiarios :isOpen.sync="isModLisBen" :Membresia="this.MembresiaSelec"></list-beneficiarios>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import ApiBase from '@/functions/axios/axiosNetBase'
import EndPointNet from "@/functions/axios/axios-bearer";

export default {
  name: 'Membresias',
  components: {
    ButtonMembresia: () => import('@/components/ButtonMembresia.vue'),
    ListColectivos: () => import('@/components/Forms/ColectivosListComponent.vue'),
    ListPagos: () => import('@/components/ListPagoComponent.vue'),
    ListAgentes: () => import('@/components/Forms/AgentesListComponent.vue'),
    ListBeneficiarios: () => import('@/components/ListBeneficiariosComponent'),
    EspecialidadesComponent: () => import('@/components/EspecialidadesComponent.vue'),
    MedicosComponent: () => import('@/components/MedicosComponent.vue'),
    BenediciadosComponent: () => import('@/components/Agente/BeneficiadosComponent.vue'),
    CitasMedicas: () => import('@/components/CitasMedicas.vue')
  },
  computed: {
    ...mapGetters(['getCODAGE'])
  },
  watch: {
    "$route.params.mem"(newVal) {
      console.log(newVal)
      if (newVal !== null && newVal !== undefined) {
        this.Nombremem = newVal;
        this.getMembresiasAgente()
      }
    }
  },
  mounted() {
    this.Nombremem = this.$route.params.mem || null;
    if (this.Nombremem !== null) {
      this.getMembresiasAgente()
    }
  },
  data() {
    return {
      RecargaListCitas: '',

      Nombremem: null,
      isModalCita: false,
      MembresiaSelecNumCitas: '',
      MembresiaSelecEstatus: 0,
      especialidad: '',
      medico: '',
      beneficiado: '',

      verOptDownloadCole: false,
      grouped: {},
      totalbajas: 0,
      MembresiaSelec: null,
      NumRecibosSelec: 0,
      isModLisBen: false,
      isModLisPagos: false,
      idcolectividad: null,
      idagente: null,
      activas: 0,
      suspendidas: 0,
      search: '',
      contratos: [],
      headers: [
        //{ text: "#", value: "index", align: "start" },
        { text: 'Agente', value: 'Agente', sortable: false },
        { text: 'Membresia', value: 'GUID', sortable: true },
        //{ text: 'Citas', value: 'NumCitas', sortable: false },
        //{ text: 'Urgencias', value: 'MontoUrgencias', sortable: false },
        { text: 'Plan', value: 'Plan', sortable: true },
        //{ text: 'Coberturas', value: 'Coberturas', sortable: false },
        { text: 'Vigencia Inicio', value: 'FecIniVig', sortable: true, width: 50 },
        { text: 'Alta', value: 'FecAlta', sortable: true, width: 50 },
        { text: 'Contratante', value: 'Contratante', sortable: true, width: 100 },
        { text: 'Titular', value: 'Titular', sortable: false },
        //{ text: 'Colectividad', value: 'Colectividad', sortable: false },
        // { text: 'Contacto', value: 'ContNumTel', sortable: false, width: 50 },
        //{ text: 'Recibos', value: 'TotRecibos', sortable: false },
        { text: 'Estatus', value: 'Estatus', sortable: true },
        { text: 'Opciones', value: 'Membresia', sortable: false },
      ],
      customFooterProps: {
        itemsPerPageText: "Elementos por página:", // Texto para "Items per page"
        itemsPerPageAllText: "Todos", // Texto personalizado para "All"
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
        'items-per-page-text': 'Filas por página'
      }
    }
  },
  methods: {
    modalCita(item) {
      console.log(item)
      this.MembresiaSelec = item.GUID
      this.MembresiaSelecNumCitas = item.NumCitas
      this.MembresiaSelecEstatus = item.Estatus
      this.isModalCita = true
    },
    cerrar() {
      this.isModalCita = false
    },
    updateEsp(esp) {
      this.medico = ''
      this.especialidad = esp
    },
    updateMed(med) {
      this.medico = med?.IdMedico
    },
    updateBen(ben) {
      this.beneficiado = ben?.Id
    },
    verPagos(mem, numrecibos) {
      this.MembresiaSelec = mem
      this.NumRecibosSelec = numrecibos
      this.isModLisPagos = true
    },
    verBeneficiarios(mem) {
      this.MembresiaSelec = mem
      this.isModLisBen = true
    },
    crearCita() {
      if (this.MembresiaSelec !== '' && this.especialidad !== '' && this.medico !== '' && this.beneficiado !== '') {
        this.$loading(true);
        EndPointNet(`membresia/citamedica`, "POST",
          {
            idMem: this.MembresiaSelec,
            idEsp: this.especialidad,
            idMed: this.medico,
            idBen: this.beneficiado
          }
        )
          .then((response) => {
            this.especialidad = ''
            this.medico = ''
            this.beneficiado = ''
            //this.isModalCita = false
            this.RecargaListCitas = response.data
            if (response.status === 200) {
              this.nuevacita = response.data;
              this.getData()
              this.getNumCitas()
              // this.search = response.data

              this.$loading(false);
              this.$swal({
                position: 'top-end',
                title: 'Correcto',
                text: 'Su pase médico se genero correctamente',
                icon: 'success',
                confirmButtonText: 'Cerrar',
                showCloseButton: false,
                showConfirmButton: false,
                timer: 1500
              })
            } else {
              this.isModalCita = false
              this.$loading(false);
              this.$swal({
                title: 'Infomación',
                text: 'No tienes pases sificientes puedes hacer una recarga de 5 a un costo especial contacta a tu agente.',
                icon: 'warning',
                confirmButtonText: 'Aceptar'
              });
            }
          });
      } else {
        this.especialidad = ''
        this.medico = ''
        this.beneficiado = ''
        this.$swal({
          // position: 'top-end',
          title: 'Infomación',
          text: `Selecciona una especialidad, un médico y para quien será el pase.`,
          icon: 'warning',
          confirmButtonText: 'Cerrar',
          showCloseButton: false
          // timer: 1500,
        })
      }
    },
    getData() {
      this.$loading(true);
      EndPointNet(`membresia/citas${this.MembresiaSelec}`, "GET")
        .then((response) => {
          if (response.status === 200) {
            this.list = response.data;
            this.$loading(false);
          }
        })
        .catch(() => {
          this.$loading(false);
        });
    },
    getNumCitas() {
      this.$loading(true);
      EndPointNet(`Membresia/Numerocitas${this.MembresiaSelec}`, "GET")
        .then((response) => {
          if (response.status === 200) {
            this.estado = response.data[0].Estatus
            this.numerocitas = response.data[0].NumCitas
            this.$loading(false);
          }
        })
        .catch(() => {
          this.$loading(false);
        });
    },
    Buscar() {
      this.getMembresiasAgente()
    },
    changeColectividad(value) {
      if (value) {
        this.idcolectividad = value
        this.verOptDownloadCole = true
      } else {
        this.idcolectividad = null
        this.verOptDownloadCole = false
      }
    },
    changeAgent(value) {
      this.idagente = value
    },
    getMembresiasAgente() {
      this.$loading(true)
    
      //var url = this.idcolectividad !== null ? `getMembresias?CodCole=${this.idcolectividad}` : `getMembresias`
      var url = `getMembresias?Agente=${this.idagente}&Colectivo=${this.idcolectividad}&Nombremem=${this.Nombremem}`
      ApiBase(
        url,
        'GET'
      ).then((response) => {
        if (response.status === 200) {
          if (response.data) {
            this.contratos = response.data
            this.activas = this.contratos.filter(item => item.Estatus === 'ACTIVO').length;
            this.suspendidas = this.contratos.filter(item => item.Estatus === 'SUSPENDIDO').length;
            this.totalbajas = this.contratos.filter(item => item.Estatus === 'BAJA').length;
            this.$loading(false)
          }
        }
      })
        .catch(() => {
          this.$loading(false)
          this.$swal({
            position: '',
            html: `Ha ocurrido un error inesperado, <b>intenta mas tarde</b> .`,
            icon: 'error',
            confirmButtonText: 'Cerrar',
            showCloseButton: true,
            showConfirmButton: true,
          })
        });
    },
    DescargaZip() {
      this.$loading(true)
      var urltarjet = `${process.env.VUE_APP_FORMATOS}Tarjeta/Colectividad?guid=${this.idcolectividad}`
      var newTab = window.open(urltarjet);
      if (newTab) {
        newTab.focus();
        this.$loading(false)
      }
    }
  }
}

</script>
<style lang="scss" scoped></style>
