<template>
  <v-container>
    <h2 class="azulfuerte--text"> Médicos especialistas </h2>
    <p class="gris--text">Selecciona una especialidad para ver los médicos disponibles. Explora todo el catálogo y elige
      al que mejor se adapte a tus necesidades.</p>
    <!-- <v-subheader class="pa-0">Selecciona una especialidad para ver los médicos a los que puedes acudir.</v-subheader> -->
    <v-row>
      <v-col cols="12" md="5" lg="5" sm="12">
        <especialidades-component @value-changed="onChangeEsp" :initialValue="this.select"></especialidades-component>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="doctor in doctors" :key="doctor.idusuario" cols="12" sm="6" md="4" lg="3">
        <v-card class="text-center rounded-xl elevation-4" color="blue-lighten-5">
          <!-- Imagen con efecto hover -->
          <v-img :src="doctor.Imagen || 'https://medicover.mx/img/defaultdoctor.jpg'"
            :alt="'Avatar de ' + doctor.Nombre" height="140" cover class="rounded-t-xl hover-scale" />

          <v-card-title class="text-h6 font-weight-bold">
            {{ doctor.Nombre }}
          </v-card-title>

          <v-card-subtitle>
            <v-rating :model-value="doctor.Rating || 5" length="5" color="amber" density="compact" half-increments
              size="18" />
            <small class="text-blue-darken-2">
              {{ doctor.Especialidad }} • ⭐ {{ doctor.Rating || '5.0' }} ({{ doctor.NumConsultas || 0 }})
            </small>
          </v-card-subtitle>

          <v-card-text class="text-body-2 text-blue-darken-4">
            <v-icon color="blue-darken-3" size="18" class="mr-1">mdi-phone</v-icon> {{ doctor.Tel }} <br>
            <v-icon color="blue-darken-3" size="18" class="mr-1">mdi-hospital-building</v-icon> Consultorio: {{
              doctor.Consultorio }} <br>
            <v-icon color="blue-darken-3" size="18" class="mr-1">mdi-map-marker</v-icon> {{ doctor.Direccion }}
          </v-card-text>

          <!-- Contenedor de botones responsivos -->
          <v-card-actions class="d-flex flex-column flex-sm-row justify-center pb-4">
            <v-btn color="green-accent-4" :href="'tel:' + doctor.Tel" class="btn-hover flex-grow-1 mx-1"
              prepend-icon="mdi-phone" variant="elevated">
              Llamar
            </v-btn>

            <v-btn :href="`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(doctor.Direccion)}`"
              target="_blank" color="blue-accent-4" class="btn-hover flex-grow-1 mx-1" prepend-icon="mdi-map-marker"
              variant="elevated">
              Ver Mapa
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

    </v-row>
  </v-container>
</template>
<script>
import ApiBase from '@/functions/axios/axiosNetBase'
import EspecialidadesComponent from "@/components/EspecialidadesComponent.vue";

export default {
  metaInfo: {
    title: 'Medicover - Especialidades médicas',
    meta: [
      {
        name: 'description',
        content: 'Descubre nuestro catálogo médico con los especialistas más destacados, listos para cuidar de tu salud y bienestar.'
      }
    ]
  },
  components: {
    EspecialidadesComponent
  },
  methods: {
    handleRowClick(item) {
      if (window.getSelection().toString().length === 0) {
        // console.log(JSON.parse(localStorage.getItem('permisos')))
        this.nombredoctor = item.nombre + ' ' + item.apaterno + ' ' + item.amaterno
        this.telefono = item.tels // Use router.push here
        this.$swal({
          // position: 'top-end',
          title: 'Doctor',
          // input: 'text',
          icon: 'phone',
          html:
            '<b>' + this.nombredoctor + '</b>,<br/>' +
            '<br/><a href="tel:' + this.telefono + '"><h2 style="color:blue">Llamar</h2></a><br/> ',
          confirmButtonText: 'Cerrar',
          showCloseButton: true,
          showConfirmButton: true,
          closeButtonText: 'cerrar',
          cancelButtonColor: '#d33',
          confirmButtonColor: '#3085d6'
          // timer: 1500,
        }).then((data) => {
          console.log(data)
        })
      }
    },
    llamando(telefono) {
      this.telefono = telefono // Use router.push here
      window.location.href = 'tel:' + telefono
      // window.open('tel:' + telefono)
    },
    onBusca(value) {
      console.log(value)
    },
    onChangeEsp(event) {
      console.log(event)
      this.idespecialidad = event
      this.getDoctors()
    },
    onChangePago(event) {
      this.pagos = event.num
      this.Calculos()
    },
    async onChangeCheck() {
      this.getDoctors()
    },
    async getDoctors() {
      this.$loading(true)
      if (this.idespecialidad !== '') {
        this.$loading(true)
        var url = `getMedicosEspecialidad?IdEsp=${this.idespecialidad}`;
        ApiBase(
          url,
          'GET'
        ).then((response) => {
          this.$loading(false)
          if (response.status === 200) {
            if (response.data) {
              this.doctors = response.data
            }
          }
        })
          .catch((error) => {
            this.$loading(false)
            console.log(error)
            this.$swal({
              position: '',
              html: `Ha ocurrido un error inesperado, <b>intente mas tarde</b> . ${error}`,
              icon: 'error',
              confirmButtonText: 'Cerrar',
              showCloseButton: true,
              showConfirmButton: true,
            })
          });
      }
    }
  },
  data() {
    return {
      rating: 5,
      nombredoctor: '',
      telefono: '',
      ancho: window.innerWidth,
      largo: window.innerHeigth,
      name: 'Cotizador',
      title: 'Cotizador',
      select: '',
      // select: { nombre: 'Plan', consultas: '0' },
      pagodefault: { num: 1, nombre: 'ANUAL' },
      items: [],
      search: '',
      doctors: [],
      headersdoctors: [
        {
          text: 'Nombres',
          align: 'start',
          filterable: false,
          value: 'nombre'
        },
        { text: 'Apellido', value: 'apaterno' },
        { text: 'Apellido', value: 'amaterno' },
        { text: 'Correo', value: 'mail' },
        { text: 'Dirección', value: 'direccion' },
        { text: 'Teléfono', value: 'tels' }
      ],
      idespecialidad: '',
    }
  }
}
</script>
<style lang="scss"></style>
