<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <p class="gris--text">Buscador de citas por <strong>un periodo de tiempo</strong>, o por número de
          recibo.</p>
        <v-switch v-model="searchFecCierre" :label="`Buscar por fecha de cierre`"></v-switch>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-chip class="ma-1" color="azul_med" outlined label>
          Creadas : {{ this.creadas }}
        </v-chip>
        <v-chip class="ma-1" color="rojoalert" outlined label>
          <v-icon start>mdi-alert-circle-outline</v-icon>
          Cerradas : {{ this.cerradas }}
        </v-chip>
      </v-col>
    </v-row>
    <v-form>
      <v-row>
        <v-col cols="12" xs="8" sm="6" md="3" lg="2" xl="2">
          <v-menu v-model="menuStart" :close-on-content-click="false" transition="scale-transition" offset-y
            min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field outlined dense v-model="dateStart" label="Fecha de inicio" prepend-icon="mdi-calendar"
                readonly v-bind="attrs" v-on="on" :disabled="(porID)"></v-text-field>
            </template>
            <v-date-picker v-model="dateStart" no-title scrollable :max="today" @input="menuStart = false"
              locale="es"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" xs="8" sm="6" md="3" lg="2" xl="2">
          <v-menu v-model="menuEnd" :close-on-content-click="false" transition="scale-transition" offset-y
            min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field outlined dense v-model="dateEnd" label="Fecha de fin" prepend-icon="mdi-calendar" readonly
                v-bind="attrs" v-on="on" :disabled="(porID)"></v-text-field>
            </template>
            <v-date-picker v-model="dateEnd" no-title scrollable :min="dateStart" @input="menuEnd = false"
              locale="es"></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" xs="8" sm="6" md="3" lg="2" xl="2">
          <v-text-field class="col" v-model="porIdRecibo" label="Id recibo" single-line hide-details outlined dense
            clearable></v-text-field>
        </v-col>
        <v-col class="mr-1">
          <v-btn color="azul_med" @click.native="Buscar" dark>Buscar</v-btn>
        </v-col>
        <v-col cols="12" xs="8" sm="6" md="3" lg="4" xl="2">
          <list-medicos :propSelect="idMedicoSelcted" @idmedico-change="changeIdMedico" ></list-medicos>
        </v-col>
      </v-row>
      <div class="cont-bord-gris">
        <v-row justify="end">
          <v-col sm="12" md="4" lg="4" xl="4">
            <v-text-field class="col" v-model="search" append-icon="mdi-magnify" label="Buscar en resultados"
              single-line hide-details outlined dense @change="comp_change()"></v-text-field>
          </v-col>
        </v-row>
        <v-data-table :items-per-page="-1" calculate-widths :headers="headers" :items="Result" item-key="IdCita"
          :search="search" :group-by="['Agente']" :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
            'items-per-page-text': 'Filas por página'
          }" no-data-text="No hay datos disponibles">
          <template v-slot:[`item.created_at`]="{ item }">
            <td> <span v-if="item.created_at"> {{ $formatDate(item.created_at) }}</span>
              <span v-else><v-icon dense color="red darken-2">
                  mdi-close
                </v-icon></span>
            </td>
          </template>
          <template v-slot:[`item.Colectividad`]="{ item }">
            <td>
              <div class="small-chip">
                {{ item.Colectividad }}
              </div>
            </td>
          </template>
          <template v-slot:[`item.Medico`]="{ item }">
            <td>
              <div class="small-chip">
                {{ item.Medico }}
              </div>
              {{ item.Especialidad }}
            </td>
          </template>
          <template v-slot:[`item.Beneficiado`]="{ item }">
            <td>
              <div class="small-chip azul_med--text">
                {{ item.Parentesco }}
              </div>
              {{ item.Beneficiado }}
            </td>
          </template>
          <template v-slot:[`item.Pagado`]="{ item }">
            <td class="text-end"><span>{{ $formatCurrency(item.Pagado)
                }}</span></td>
          </template>
          <template v-slot:[`item.FecCierre`]="{ item }">
            <td v-if="item.FecCierre !== null" > {{ $formatDate(item.FecCierre)
                }}</td>
              <td v-else class="rojoalert--text" >ABIERTA</td>
          </template>
          <template v-slot:[`item.Estado`]="{ item }">
            <td v-if="item.FecCierre !== null" class="estatus-activo">CERRADA <small> {{ $formatDate(item.FecCierre)
                }}</small></td>
            <td v-else class="rojoalert--text">ABIERTA</td>
          </template>
          <template v-slot:[`item.Opciones`]="{ item }">
            <v-menu offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn red icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical-circle-outline</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item :disabled="!(item.FecCierre === null)">
                  <v-list-item @click="CierraCita(item)">
                    <v-list-item-title> Cerrar cita</v-list-item-title>
                  </v-list-item>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </div>
    </v-form>
  </v-container>
</template>

<script>
import ApiBase from '@/functions/axios/axiosNetBase'
import { mapGetters } from 'vuex'
export default {
  components: {
    ListMedicos: () => import('@/components/Forms/MedicosListComponent.vue')
  },
  computed: {
    ...mapGetters(['getIDE'])
  },
  watch: {
    // Observa los cambios en 'message'
    porIdRecibo(newValue, oldValue) {
      if (newValue != null && newValue.length > 0) {
        this.porID = true
      } else {
        this.porID = false
      }
      console.log('El mensaje cambió de', oldValue, 'a', newValue);
      // Aquí puedes poner la lógica que deseas ejecutar cuando 'message' cambie
    }
  },
  mounted(){
    this.Buscar();
  },
  methods: {
    changeIdMedico(data){
      if(data){
        this.idMedicoSelcted = data
        this.buscarPorMedico()
      }else{
        this.porID = false
      }
      
    },
    async CierraCita(data) {
      const result = await this.$swal({
        title: '¿Cerrar pase médico?',
        text: `Estás a punto cerrar el pase ${data.IdCita} del beneficiario ${data.Beneficiado}.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, adelante',
        cancelButtonText: 'No, cancelar',
        reverseButtons: true
      });

      if (result.isConfirmed) {
        this.$loading(true)
        var url = `updateCitas/${data.IdCita}`;
        ApiBase(
          url,
          'POST',
          this.costo //parametro normal
        ).then((response) => {
          if (response.status === 200) {
            if (response.data) {
              this.$loading(false)
              this.Buscar();
              var params = {
                Accion: "POST CIERRE CONSULTA",
                Descripcion: data.IdCita,
                Tabla: "MEDICOVER.citas",
                User: this.getIDE
              }
              this.$registerHistory(params)
              this.$swal({
                position: 'top-en',
                title: 'Correcto',
                text: 'Se cerro correctamente el pase médico.',
                icon: 'success',
                confirmButtonText: 'Cerrar',
                showCloseButton: false,
                showConfirmButton: false,
                timer: 1500
              })
            }
          }
        })
          .catch((error) => {
            this.$loading(false)
            console.log(error)
            this.$swal({
              position: '',
              html: `Ha ocurrido un error inesperado membresías agente, <b>intenta mas tarde</b> . ${error}`,
              icon: 'error',
              confirmButtonText: 'Cerrar',
              showCloseButton: true,
              showConfirmButton: true,
            })
          });
      } else if (result.dismiss === this.$swal.DismissReason.cancel) {
        // El usuario canceló (botón "No, cancelar")
        this.$swal('Cancelado', 'Tu acción fue cancelada.', 'error');
      }
    },
    buscarPorMedico(){
      this.$loading(true)
      var url = `getCitasMedico?Ide=${this.idMedicoSelcted}`;
      ApiBase(
        url,
        'GET',
        {}
      ).then((response) => {
        if (response.status === 200) {
          if (response.data) {
            this.Result = response.data
            this.creadas = this.Result.filter(item => item.created_at).length;
            this.cerradas = this.Result.filter(item => item.FecCierre !== null).length;
            this.total = this.Result.length;
            this.$loading(false)
          }
        }
        this.$loading(false)
      })
        .catch((error) => {
          this.$loading(false)
          this.$swal({
            position: '',
            html: `Ha ocurrido un error inesperado membresías agente, <b>intenta mas tarde</b> . ${error}`,
            icon: 'error',
            confirmButtonText: 'Cerrar',
            showCloseButton: true,
            showConfirmButton: true,
          })
        });
    },
    Buscar() {
      this.idMedicoSelcted= null
      this.$loading(true)
      var url = `getCitasMedicas`
      const params = {
        FecIni: this.dateStart,
        FecFin: this.dateEnd,
        Idcita: this.porIdRecibo,
        Cierre: this.searchFecCierre
      };
      ApiBase(
        url,
        'POST',
        params
      ).then((response) => {
        if (response.status === 200) {
          if (response.data) {
            this.Result = response.data
            this.creadas = this.Result.filter(item => item.created_at).length;
            this.cerradas = this.Result.filter(item => item.FecCierre !== null).length;
            this.total = this.Result.length;
            this.$loading(false)
          }
        }
        this.$loading(false)
      })
        .catch((error) => {
          this.$loading(false)
          this.$swal({
            position: '',
            html: `Ha ocurrido un error inesperado membresías agente, <b>intenta mas tarde</b> . ${error}`,
            icon: 'error',
            confirmButtonText: 'Cerrar',
            showCloseButton: true,
            showConfirmButton: true,
          })
        });
    }
  },
  data() {
    return {
      idMedicoSelcted: null,
      searchFecCierre: false,
      porID: false,
      costo: 650,
      porIdRecibo: null,
      total: 0,
      creadas: 0,
      cerradas: 0,
      Result: [],
      search: '',
      headers: [
        { text: 'IdCita', value: 'IdCita', sortable: false },
        { text: 'Tarjeta', value: 'IdTarjeta', sortable: false },
        //{ text: 'IdAgente', value: 'IdAgente', sortable: false },
        { text: 'Agente', value: 'Agente', sortable: false },
        { text: 'Beneficiado', value: 'Beneficiado', sortable: true },
        //{ text: 'Especialidad', value: 'Especialidad', sortable: false },
        { text: 'Medico', value: 'Medico', sortable: true },
        { text: 'Colectividad', value: 'Colectividad', sortable: true },
        //{ text: 'ContEmail', value: 'ContEmail', sortable: false },
        //{ text: 'ContTel', value: 'ContTel', sortable: false },   
        { text: 'Pagado', value: 'Pagado', sortable: true },
        { text: 'Creado', value: 'created_at', sortable: true },
        { text: 'Cerrado', value: 'FecCierre', sortable: true },
        { text: 'Opciones', value: 'Opciones', sortable: false },
      ],
      dateStart: (new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split('T')[0]),
      dateEnd: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menuStart: false,
      menuEnd: false,
      today: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), //new Date().toISOString().substr(0, 10) // Fecha de hoy en formato YYYY-MM-DD
    };
  },
};
</script>
