<template>
  <v-container fluid>
    <v-row dense>
      <v-col>
        <p class="gris--text">Busca tus recibos por <strong>año y mes</strong>, envíalos por correo o
          imprímelos.</p>
      </v-col>
    </v-row>
    <!-- <div class="text-h4 text-md-h4 text-lg-h4 gris--text">Listado recibos</div> -->
    <!-- <v-typography variant="h4">Busca recibos y aplica pagos.</v-typography> -->
    <v-row dense fluid>
      <v-col xs="8" sm="6" md="3" lg="2" xl="2">
        <YearPicker v-model="selectedYear" />
      </v-col>
      <!-- <v-col xs="8" sm="6" md="3" lg="3" xl="2">
        <v-text-field v-model="Busqueda"   label="Busqueda"
        @input="onInputBusqueda" outlined dense></v-text-field>
      </v-col> -->
      <v-col xs="4" sm="4" md="2" lg="1" xl="1" class="text-center">
        <v-btn color="azul_med" @click.native="onClickFunction" dark elevation="0">Buscar</v-btn>
      </v-col>
      <v-col xs="4" sm="4" md="2" lg="3" xl="1" class="text-center">
        <v-btn color="azulclaro" @click.native="onClickDescargar" dark elevation="0"> <v-icon left>mdi-printer</v-icon>
          Imprimir</v-btn>
      </v-col>
      <v-col class="d-flex justify-end">
        <div>
          <v-chip class="ma-1 px-3 font-weight-bold text-body-2" color="blue-grey lighten-4" text-color="black"
            outlined>
            <v-icon left small color="green">mdi-cash</v-icon>
            Total aplicado:
            <span class="text-green-darken-2">{{ $formatCurrency(this.totalPagados) }} de {{ $formatCurrency(this.total)
              }}</span>
          </v-chip>
          <small>Recibos pagados: <strong>{{ this.pagados }} </strong> de <strong> {{ this.num_recibos }}
            </strong></small>
          <v-progress-circular :value="Porciento" :size="60" :width="10" color="green" rotate="-90">
            {{ Porciento }}%
          </v-progress-circular>
        </div>

        <!-- <v-chip class="ma-1" color="grey" outlined label>
          Faltante: {{ $formatCurrency(this.totalPendientes) }}
      </v-chip> -->
      </v-col>
    </v-row>
    <div class="cont-bord-gris">
      <v-row no-gutters justify="end" dense>
        <v-col>
          <v-list-item-subtitle>
            <v-chip dense class="ma-1" color="azul_med" outlined label>
              Pagados: {{ this.pagados
              }}
            </v-chip>
            <v-chip dense color="red" outlined dark label>
              Impago: {{ this.impago
              }}
            </v-chip>
            <v-chip dense class="ma-1" color="grey" outlined label>
              Total: {{ this.num_recibos
              }}
            </v-chip>
          </v-list-item-subtitle>
        </v-col>
        <!-- <v-col class="text-sm-h6  red--text">
          <v-list-item-subtitle>Recibos Impago: {{ this.impago }}</v-list-item-subtitle>
        </v-col>
        <v-col class="text-sm-h6 green--text">
          <v-list-item-subtitle>Recibos pagados: {{ this.pagados }}</v-list-item-subtitle>
        </v-col>-->
        <v-col xs="6" sm="6" md="4" lg="5" xl="4">
          <v-text-field clearable v-model="search" append-icon="mdi-magnify" label="Buscar en tabla" single-line
            hide-details outlined dense @change="comp_change()"></v-text-field>
        </v-col>
      </v-row>

      <!-- show-expand :expanded.sync="expanded" :item-class="getRowClass" :items-per-page="-1" fixed-header height="60vh" -->
      <v-data-table group-by="agente" :items-per-page="-1" calculate-widths :headers="headers" :items="pagos" dense
        :search="search" item-key="idpago" class="custom-data-table" :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus',
          'items-per-page-text': 'Filas por página'
        }" no-data-text="No hay datos disponibles" @click:row="selectRow" :item-class="getRowClass">
        <template v-slot:item="{ item }">
          <tr @click="selectRow(item)" :class="{ 'selected-row': selectedRow && selectedRow === item.idpago }">
            <td>
              {{ item.idpago }}
              <!-- <br/> <v-icon color="red" size="24">mdi-file-pdf-box</v-icon> | <v-icon color="blue" size="24">mdi-file-pdf-box</v-icon> -->
            </td>
            <td :style="{ background: getColor(item.contrato) }">
              <span v-if="item.estado === '1'" class="estatus-activo">Activa</span>
              <span v-if="item.estado === '2'" class="estatus-expirado">Suspendida</span><br />
              {{ item.contrato }}
            </td>
            <template>
              <td :style="{ background: getColor(item.contrato) }">
                <div class="small-chip">
                  {{ item.NomColectivo }}
                </div>
                {{ item.contratante }}
              </td>
            </template>
            <template>
              <td :style="{ background: getColor(item.contrato) }">
                <span class="creado-style">{{ item.DatePagoFormat }}</span>
              </td>
            </template>
            <template>
              <td :style="{ background: getColor(item.contrato) }">
                <span> <strong>{{ item.recibo }}</strong> de <strong>{{ item.recibos }}</strong></span>
              </td>
            </template>
            <template>
              <td :style="{ background: getColor(item.contrato) }">
                <span v-if="item.Diferencia <= 1 && item.Diferencia > 0 && item.pagado == 0">{{ item.Diferencia }}
                  días</span>
                <span v-if="item.Diferencia > 1 && item.pagado == 0" class="estatus-retardo">{{ item.Diferencia }}
                  días</span>
                <span v-if="item.Diferencia < 0 && item.pagado == 0" style="color: olivedrab;">A tiempo</span>
                <span v-else>
                  <v-btn v-if="item.pagado === 1 && item.Factura !== null" icon
                    :href="'https://api.medicover.mx/api/verfactura?membresia=' + item.Factura + '.pdf'"
                    target="_blank">
                    <v-icon size="24" color="red">mdi-file-pdf-box</v-icon>
                  </v-btn>
                  <v-btn v-if="item.pagado === 1 && item.Factura !== null" icon
                     :href="'https://api.medicover.mx/api/verfactura?membresia=' + item.Factura + '.xml'"
                    target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                      <polyline points="14 2 14 8 20 8" />
                      <text x="6" y="19" font-size="6" fill="currentColor">&lt;XML&gt;</text>
                    </svg>
                  </v-btn>
                  <strong v-if="item.pagado === 1 && item.Factura === null">
                    POR FACTURAR
                  </strong>
                </span>
              </td>
            </template>
            <template>
              <td class="text-center" v-if="item.pagado === 0">
                IMPAGO
              </td>
              <td v-else dark class="elemento-pagado text-center">
                <div class="small-chip">
                  {{ item.FormaPago }}
                </div>
                <strong>PAGADO</strong>
              </td>
            </template>
            <template>
              <td class="text-end"><span class="estatus-activo">{{ $formatCurrency(item.pago) }}</span></td>
            </template>
            <template>
              <v-menu offset-x left :close-on-content-click="true">
                <template v-slot:activator="{ on, attrs }">
                  <td><v-btn @click="selectRow(item)"
                      :class="{ 'selected-row': selectedRow && selectedRow === item.idpago }" red icon v-bind="attrs"
                      v-on="on">
                      <v-icon>mdi-dots-vertical-circle-outline</v-icon>
                    </v-btn></td>
                </template>
                <v-list>
                  <v-list-item @click="verPagos(item.contrato)">
                    <v-list-item-icon>
                      <v-icon>mdi-list-box-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> HISTORIAL RECIBOS</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="$verRecibo(item.idpago)">
                    <v-list-item-icon>
                      <v-icon>mdi-eye-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> VER RECIBO</v-list-item-title>
                  </v-list-item>
                  <v-list-item :disabled="false">
                    <email-recibo-modal :ReciboId="item.idpago" :Membresia="item.contrato"
                      :Recibo="`${item.recibo} de ${item.recibos}`" :Titular="item.nombretitular"
                      :Monto="$formatCurrency(item.pago)" :propEmail="item.Email">
                    </email-recibo-modal>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </tr>
        </template>
        <template v-slot:expanded-item="{ item }">
          <td :colspan="headers.length">
            <v-row no-gutters>
              <v-col cols="12">
                <v-expand-transition>
                  <v-card flat>
                    <v-card-text>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Nombre contratante: {{ item.contratante }}</v-list-item-title>
                          <v-list-item-subtitle v-if="item.IdColectivo">Colectivo: {{ item.NomColectivo
                          }}</v-list-item-subtitle>
                          <v-list-item-subtitle v-if="item.estado == '1'">Estatus:
                            <strong>ACTIVO</strong></v-list-item-subtitle>
                          <v-list-item-subtitle v-else>Estatatus: <strong>SUSPENDIDO</strong></v-list-item-subtitle>
                          <v-list-item-subtitle>Número de pagos: {{ item.recibos }}</v-list-item-subtitle>
                          <v-list-item-subtitle>Renovación: {{ item.renovacion }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card-text>
                  </v-card>
                </v-expand-transition>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>
      <list-pagos :isOpen.sync="isModLisPagos" :Membresia="this.MembresiaSelec"></list-pagos>
    </div>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import ApiBase from '@/functions/axios/axiosNetBase'
import YearPicker from '@/components/AnioComponent.vue';
import EmailReciboModal from '@/components/Pagos/EmailReciboComponent.vue'
import EndPointNet from '@/functions/axios/axios-bearer'


export default {
  name: 'InicioAgente',
  components: {
    YearPicker,
    EmailReciboModal,
    ListPagos: () => import('@/components/ListPagoComponent.vue')
  },
  computed: {
    ...mapGetters(['getCODAGE']),
    duplicateColors() {
      const counts = {};
      const colors = {};
      //const colorPalette = ["#FFEB3B", "#FFCDD2", "#C8E6C9", "#BBDEFB", "#FFCCBC", "#D1C4E9"]; 
      const colorPalette = [
        "#FEF9F9", // Rosa pétalo suave
        "#FFFDF5", // Crema marfil
        "#FFFAF0", // Blanco durazno
        "#FFFCF2", // Beige nube
        "#FDF7FF", // Lavanda brisa
        "#F5F2FF", // Lila etéreo
        "#F0F9FF", // Azul celeste hielo
        "#EFFFFA", // Verde agua cristalino
        "#F6FFF0", // Verde lima neblina
        "#FFFFF0", // Amarillo algodón
        "#F8FFF8", // Verde menta nevada
        "#FCFFF4", // Amarillo pastel apagado
        "#FAFEFF", // Azul polar
        "#F4F8FF", // Azul neón apagado
        "#F0F0F0", // Gris perla claro
        "#FFFFFF", // Blanco puro
      ];

      let colorIndex = 0;

      this.pagos.forEach((item) => {
        counts[item.contrato] = (counts[item.contrato] || 0) + 1;
      });

      Object.keys(counts).forEach((key) => {
        if (counts[key] > 1) {
          colors[key] = colorPalette[colorIndex % colorPalette.length]; // Asigna colores en ciclo
          colorIndex++;
        }
      });
      return colors;
    }
  },
  mounted() {
    this.getPagosPendientes()
  },
  watch: {
    selectedYear(val) {
      if (val) {
        this.Busqueda = null
      }
    }
  },
  data: () => ({
    dialogPagoShow: false,
    selectedPago: {},
    Porciento: 0,
    snackbar: true,
    vertical: true,
    selectedRow: null,
    textAlert: '',
    NumRecibosSelec: null,
    MembresiaSelec: null,
    isModLisPagos: false,
    Busqueda: null,
    reciboSeleccionado: null,
    selectedYear: new Date().toISOString().substr(0, 7), // new Date().getFullYear().toString(), or null
    search: '',
    pagos: [],
    expanded: [],
    total: 0,
    totalPagados: 0,
    totalPendientes: 0,
    headers: [
      { text: 'Folio', value: 'idpago', sortable: true },
      { text: 'Contrato', value: 'contrato', width: '10%', sortable: true },
      { text: 'Contratante', value: 'contratante', width: '18%', sortable: true },
      { text: 'Agente', value: 'agente', sortable: true },
      { text: 'Fecha pago', value: 'DatePagoFormat', width: '10%', sortable: true },
      { text: 'Recibo', value: 'recibo', sortable: false },
      { text: 'Retardo', value: 'Diferencia', width: '10%', sortable: false },
      { text: 'Estatus', value: 'pagado', width: '10%', sortable: true },
      { text: 'Monto', value: 'pago', width: '10%', sortable: false, align: 'end' },
      { text: 'Opciones', value: 'ops', width: '8%', sortable: false, align: 'center' },
    ],

    num_recibos: 0,
    pagados: 0,
    impago: 0
  }),
  methods: {
    getColor(contrato) {
      //const color = this.duplicateColors[contrato];
      //return color ? `3px solid ${color}` : "none";
      return this.duplicateColors[contrato] || "transparent"; // Si no es duplicado, fondo transparente
    },
    saveEdit(editedItem) {
      console.log(editedItem)
      this.$loading(true)
      EndPointNet(
        `Payment/${this.editedItem.contrato}`, //${localStorage.getItem('id')}
        'GET'
      ).then((response) => {
        if (response.status === 200) {
          this.$loading(true)
          if (response.data) {
            if (response.data) {
              this.datosFiscales = { ...response.data };
            }
          } else {
            this.datosFiscales = { RFC: "XAXX010101000", Correo: null };
          }
        }
      })
        .catch(() => {
          this.$loading(true)
          this.$swal({
            position: '',
            // title: 'Valida',
            //text: 'Se genero un error',
            html: `Ups, tuvimos un error no esperado <b>intenta más tarde</b> .`,
            icon: 'error',
            confirmButtonText: 'Cerrar',
            showCloseButton: true,
            showConfirmButton: true,
            //timer: 1500,
          })
        });
    },
    onClickDescargar() {
      this.$loading(true)
      var urltarjet = `${process.env.VUE_APP_BUCKETFORMATOS}Pagos/reportePagos?qry=${this.selectedYear}&CodAge=${this.getCODAGE}` //agente
      var newTab = window.open(urltarjet);
      if (newTab) {
        newTab.focus();
        this.$loading(false)
      }
    },
    selectRow(item) {
      this.selectedRow = item.idpago;
    },
    getRowClass(item) {
      return this.selectedRow && this.selectedRow === item.idpago ? 'selected-row' : '';
      //return item.pagado === 1 ? 'pagado-row' : 'impago-row';
    },
    verPagos(data) {
      this.MembresiaSelec = data
      this.isModLisPagos = true
    },
    editItem(item) {
      console.log(item)
    },
    deleteItem(item) {
      console.log(item)
    },
    onCleardate() {
      this.selectedYear = null
      this.getPagosPendientes()
    },
    onClickFunction() {
      this.getPagosPendientes()
    },
    verSoloPendientes() {
      this.selectedYear = null
      this.getPagosPendientes()
    },
    getPagosPendientes() {
      this.$loading(true)
      var url = `getCobranza?qry=${this.selectedYear}&CodAge=${this.getCODAGE}`
      ApiBase(
        url,
        'GET'
      ).then((response) => {
        if (response.status === 200) {
          if (response.data) {
            this.$loading(false)
            this.pagos = response.data
            this.num_recibos = this.pagos.length;
            this.impago = this.pagos.filter(i => i.pagado === 0).length
            this.pagados = this.pagos.filter(i => i.pagado === 1).length
            this.Porciento = ((this.pagados / this.num_recibos) * 100).toFixed(0);
            this.total = this.pagos.reduce((total, pago) => total + pago.pago, 0);
            this.totalPagados = this.pagos.filter(pago => pago.pagado === 1)
              .reduce((total, pago) => total + pago.pago, 0);
            this.totalPendientes = this.pagos.filter(pago => pago.pagado === 0)
              .reduce((total, pago) => total + pago.pago, 0);
          }
        }
      })
        .catch(() => {
          this.$loading(false)
          this.$swal({
            position: '',
            // title: 'Valida',
            //text: 'Se genero un error',
            html: `Ha ocurrido un error inesperado, <b>intenta mas tarde</b> .`,
            icon: 'error',
            confirmButtonText: 'Cerrar',
            showCloseButton: true,
            showConfirmButton: true,
            //timer: 1500,
          })
        });
    },
    registrarPago(item) {
      this.PagoSelect.Membresia = item.contrato
      this.PagoSelect.PagoNum = item.recibo + ' de ' + item.recibos
      this.PagoSelect.Folio = item.idpago
    },
    onInputBusqueda(value) {
      this.selectedYear = null,
        this.Busqueda = value
    }
  }
}
</script>
<style scoped>
.creado-style {
  font-weight: bold;
  color: #696969;
}

.impago-row .v-data-table__wrapper tbody tr {
  background-color: rgb(220, 239, 220) !important;
}

.custom-data-table .pagado-row {
  background-color: rgb(220, 239, 220) !important;
}

.custom-data-table .impago-row {
  background-color: rgb(255, 255, 25) !important;
}

.custom-data-table .older-row {
  background-color: #ffe4e1;
  /* Color para filas con edad > 30 */
}

.custom-data-table .younger-row {
  background-color: #e0ffff;
  /* Color para filas con edad <= 30 */
}

.elemento-pagado {
  background-color: #C8E6C9;
}

.small-chip {
  font-size: 10px;
  color: #575757;
}

.selected-row {
  background-color: #a0d0f4 !important;
  /* Color de fondo para la fila seleccionada */
}
</style>