<template>
    <div>
        <!-- Imagen tipo cintilla -->
        <v-img src="https://medicover.mx/img/pagoenlinea.png" height="140" contain class="rounded"></v-img>

        <v-container class="d-flex justify-center align-center">
            <v-row v-if="Id" class="d-flex justify-center">
                <!-- Tarjeta de Pago -->
                <v-col cols="12" md="5">
                    <v-card class="pa-4" elevation="6">
                        <v-card-title class="text-h6 font-weight-bold text-center">Pago en Línea</v-card-title>

                        <v-card-subtitle class="text-center">Ingresa los datos de tu tarjeta</v-card-subtitle>
                        <v-form ref="formPago" v-model="formValido" lazy-validation>
                        <v-row>
                            <v-col cols="10">
                                <v-text-field label="Número de Tarjeta" outlined dense v-mask="'#### #### #### ####'"
                                    prepend-inner-icon="mdi-credit-card" v-model="Pago.NumeroTarjet"
                                    @input="changeNumTarjet" :rules="[ruleRequired, ruleCardNumber]"
                                    maxlength="19"></v-text-field>
                            </v-col>
                            <v-col cols="2">
                                <v-img class="bg-grey-lighten-2" v-show="!this.visa" height="40" cover
                                    src="https://medicover.mx/img/visa.svg"></v-img>
                                <v-img v-show="!this.mastercard" class="bg-grey-lighten-2" height="40" cover
                                    src="https://medicover.mx/img/mastercard.svg"></v-img>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field label="MM/AA"  v-model="Pago.Fecha" outlined dense v-mask="'##/##'"
                                    prepend-inner-icon="mdi-calendar" :rules="[ruleRequired]"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field label="CVV"  v-model="Pago.CVV"  outlined dense type="password" v-mask="'###'"
                                    prepend-inner-icon="mdi-lock" maxlength="3" :rules="[ruleRequired]"></v-text-field>
                            </v-col>
                        </v-row>

                        <v-text-field label="Titular de la Tarjeta" outlined dense v-model="Pago.NombreTarjet"
                            prepend-inner-icon="mdi-account" :rules="[ruleRequired,ruleNombre]"
                            @input="Pago.NombreTarjet = Pago.NombreTarjet.toUpperCase()"></v-text-field>

                        <v-btn color="primary" :disabled="!formValido" @click="procesarPago" block class="mt-4">
                            Pagar Ahora
                        </v-btn>
                    </v-form>
                    </v-card>
                </v-col>

                <!-- Detalles del Pago -->
                <v-col cols="12" md="4">
                    <v-card class="pa-4" elevation="6">
                        <v-card-title class="text-h6 font-weight-bold text-center">Detalles del Pago</v-card-title>

                        <v-divider></v-divider>

                        <v-list dense>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Plan:</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-content class="text-right font-weight-bold">
                                    <span>{{this.DatosRecibo.Plan}}</span>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Membresía:</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-content class="text-right font-weight-bold">
                                    <span>{{this.DatosRecibo.Membresia}}</span>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Folio:</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-content class="text-right font-weight-bold">
                                    <span>{{this.DatosRecibo.FolioRecibo}}</span>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Recibo:</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-content class="text-right font-weight-bold">
                                    <span>{{this.DatosRecibo.NumRecibo}}</span>
                                </v-list-item-content>
                            </v-list-item>
                            
                            <v-divider></v-divider>
                            <v-list-item>
                                <v-list-item-content>
                                <v-list-item-title>
                                    Total a Pagar:
                                </v-list-item-title>
                                </v-list-item-content>
                                
                                <v-list-item-content class="text-right text-h5 font-weight-bold text-primary">
                                <span>{{ $formatCurrency(DatosRecibo.Total) }}</span>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-col>
            </v-row>

            <!-- <v-row v-else class="d-flex flex-column align-center text-center">
                <p class="mb-2 text-h6 font-weight-bold">Búsqueda de recibo</p>
                <v-form ref="searchForm" @submit.prevent="submitForm">
                    <v-col cols="12" md="6">
                        <v-text-field prepend-inner-icon="mdi-magnify" v-model="search" :rules="[ruleNumber]"
                            label="Número de recibo" outlined dense clearable required  style="max-width: 400px;"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-btn color="azulfuerte" type="submit" :disabled="!search" dark>Buscar</v-btn>
                    </v-col>
                </v-form>
            </v-row> -->
            <!-- <v-row justify="center">
                <v-col cols="12" md="8" lg="6">
                    <v-card class="pa-5">
                        <v-card-title class="text-h5 text-center">Búsqueda de Recibo</v-card-title>
                        <v-card-text>
                            <v-form ref="searchForm" @submit.prevent="submitForm">
                                <v-text-field v-model="search" label="Número de recibo" prepend-inner-icon="mdi-magnify"
                                    clearable outlined dense class="w-100" :rules="[ruleNumber]"
                                    required></v-text-field>

                                <v-btn color="primary" type="submit" block :disabled="!search" class="mt-3">
                                    Buscar
                                </v-btn>

                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row> -->
        </v-container>
    </div>
</template>

<script>
export default {
    data() {
        return {
            formValido: false,
            ruleCardNumber: (v) =>
                /^4\d{3} \d{4} \d{4} \d{4}$|^5[1-5]\d{2} \d{4} \d{4} \d{4}$/.test(v) ||
                "Tarjeta inválida (Visa/Mastercard)",
            ruleExpDate: (v) => {
                if (!/^\d{2}\/\d{2}$/.test(v)) return "Formato inválido (MM/AA)";
                const [mes, año] = v.split("/").map(Number);
                const añoActual = new Date().getFullYear() % 100;
                const mesActual = new Date().getMonth() + 1;
                if (año < añoActual || (año === añoActual && mes < mesActual))
                return "Tarjeta expirada";
                return true;
            },
            ruleCVV: (v) => /^\d{3}$/.test(v) || "CVV inválido (3 dígitos)",
            Id: null,
            visa: true,
            mastercard: true,
            Pago: {
                NombreTarjet: null,
                NumeroTarjet: null,
                Fecha: null
            },
            DatosRecibo: {
                FolioRecibo: '21322223123',
                Membresia: 'C124017655122308',
                Total: 1.00,
                Titular: 'Karen Castillo Rodríguez',
                NumRecibo: '1 de 12',
                Nombres: 'Karen Castillo Rodríguez',
                Plan: 'PLUS FAMILIAR',
            },
            //search: null
        };
    },
    created() {
        this.Id = this.$route.params.recibo || null; // Obtener el ID de la URL
        if (this.Id) {
            console.log(this.Id)
        }
    },
    
    methods: {
        submitForm() {
            if (this.$refs.searchForm.validate()) {
                alert("Buscando recibo: " + this.search);

                // Aquí puedes agregar la lógica para realizar la búsqueda
            }
        },
        changeNumTarjet(value) {
            console.log(value.trim().length)
            let firstChar1 = value[0];
            if (firstChar1 === "4") { //visa
                this.mastercard = true
                this.visa = false
            }
            if (firstChar1 === "5") {//mastercard
                this.visa = true
                this.mastercard = false
            }
        },
        procesarPago() {
        if (this.$refs.formPago.validate()) {
            alert("✅ Pago exitoso con la tarjeta de " + this.Pago.Titular);
        }
        },
    }
};
</script>
