<template>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="selectedDate"
          label="Selecciona año y mes"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
          outlined
          dense
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="selectedDate"
        :type="'month'"
        :format="'MM/yyyy'"
        @input="updateValue"
        :max="maxYear"
        locale="es"
      ></v-date-picker>
    </v-menu>
  </template>
  
  <script>
  export default {
    props: {
      value: String
    },
    data() {
      return {
        menu: false,
        selectedDate: this.value,
        maxYear: new Date().getFullYear().toString()+1
      };
    },
    watch: {
      value(newValue) {
        this.selectedDate = newValue;
      }
    },
    methods: {
      updateValue(date) {
        this.$emit('input', date);
        this.menu = false;
      }
    }
  };
  </script>
  
  <style scoped>
  /* Puedes agregar tus estilos aquí */
  </style>