<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
            <v-card-title class="gris--text">
                APLICAR PAGO DE {{ this.editedItem.contratante }}
            </v-card-title>
            <v-spacer></v-spacer>

            <v-card-subtitle class="text-grey">

                    <v-alert type="warning" prominent class="mt-4" icon="mdi-information-outline">
                        Al ser aplicado el pago se generará la <strong>factura</strong>, es necesario validar la
                        información antes de aplicar.
                    </v-alert>

                Se aplicará el pago <strong>{{ this.editedItem.recibo }}</strong> de <strong>{{ this.editedItem.recibos
                    }}</strong>. Por la cantidad de <strong> {{ $formatCurrency(
                        this.editedItem.pago) }}</strong><br />
                Se facturará a 
                <!-- <strong>PUBLICO EN GENERAL</strong>  -->
                <strong>{{ this.datosFiscales?.RFC }}</strong> y se envia a el correo <strong>{{
                    this.datosFiscales?.Correo }}</strong>.
            </v-card-subtitle>
            <v-card-text>
                <v-form ref="form" v-model="valid">
                    <v-select return-object single-line v-model="FormaPago.CodPago" :items="tipopagos"
                        :item-text="'Nombre'" :item-value="'CodPago'" label="¿Como pago? " color="blue" outlined dense
                        :rules="[ruleSelectDetallepago]"></v-select>
                    <v-text-field v-model="Referencia" maxlength="4" :rules="[ruleRef4dig]" label="¿Tiene referencia?"
                        outlined dense></v-text-field>
                    <!-- <v-text-field v-model="editedItem.Membresia" label="Nombre"></v-text-field>
            <v-text-field v-model="editedItem.email" label="Email"></v-text-field> -->
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey darken-1" text @click="close">Cancelar</v-btn>
                <v-btn color="green darken-1" text :disabled="!valid" @click="save">REGISTRAR</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import EndPointNet from '@/functions/axios/axios-bearer'

export default {
    props: {
        value: Boolean, // Para controlar la visibilidad del modal
        item: Object // El ítem a editar
    },
    data() {
        return {
            valid: false,
            tipopagos: null,
            FormaPago: {
                Nombre: null,
                CodPago: ''
            },
            Referencia: null,
            dialog: this.value,
            editedItem: { ...this.item },
            datosFiscales: null
        };
    },
    watch: {
        value(val) {
            this.dialog = val;
        },
        item(val) {
            this.editedItem = { ...val };
            if (val) { // Cuando el modal se abre, obtenemos datos
                this.obtieneDatosFiscales();
            }
        }
    },
    mounted() {
        this.obtieneTipodePago()
    },
    methods: {
        close() {

            this.$emit("input", false);
            this.FormaPago = {
                Nombre: null,
                CodPago: ''
            },
                this.Referencia = null
            this.editedItem = {}
        },
        save() {
            this.editedItem = { ...this.editedItem, CodPago: this.FormaPago.CodPago.CodPago, Referencia: this.Referencia }
            this.$emit("save", this.editedItem);
            this.FormaPago = {
                Nombre: null,
                CodPago: ''
            },
            this.Referencia = null
            this.editedItem = {}         
            this.close(); 
        },
        obtieneTipodePago() {
            EndPointNet(
                `catalogo/getAlltipopagos`, //${localStorage.getItem('id')}
                'GET'
            ).then((response) => {
                if (response.status === 200) {
                    if (response.data) {
                        this.tipopagos = response.data
                    }
                }
            })
                .catch(() => {
                    this.$swal({
                        position: '',
                        // title: 'Valida',
                        //text: 'Se genero un error',
                        html: `Ups, tuvimos un error no esperado <b>intenta más tarde</b> .`,
                        icon: 'error',
                        confirmButtonText: 'Cerrar',
                        showCloseButton: true,
                        showConfirmButton: true,
                        //timer: 1500,
                    })
                });
        },
        obtieneDatosFiscales() {

            EndPointNet(
                `DatosFiscales/${this.editedItem.contrato}`, //${localStorage.getItem('id')}
                'GET'
            ).then((response) => {
                if (response.status === 200) {
                    if (response.data) {
                        if (response.data) {
                            this.datosFiscales = { ...response.data };
                        }
                    } else {
                        this.datosFiscales = { RFC: "XAXX010101000", Correo: null };
                    }
                }
            })
                .catch(() => {
                    this.$swal({
                        position: '',
                        // title: 'Valida',
                        //text: 'Se genero un error',
                        html: `Ups, tuvimos un error no esperado <b>intenta más tarde</b> .`,
                        icon: 'error',
                        confirmButtonText: 'Cerrar',
                        showCloseButton: true,
                        showConfirmButton: true,
                        //timer: 1500,
                    })
                });

        }
    }
};
</script>