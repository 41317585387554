import axios from 'axios';
//import router from '@/router'; // Asegúrate de importar Vue Router
import store from '@/store'; // Importa Vuex si lo usas

export default async function EndPointNet(url, method = 'GET', datos) {
  const config = {
    method,
    headers: {
      'Content-Type': 'application/json'
    }
  };

  // Agregar datos al body si es necesario
  if (datos) {
    config.data = datos;
  }

  // Agregar el token si está disponible
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  try {
    // Realizar la petición con Axios
    const response = await axios(url, config);
    return response;
  } catch (error) {
    // Si la respuesta es 401, manejar el cierre de sesión y redirigir
    if (error.response && error.response.status === 401) {
      store.dispatch('logout'); // Limpia la sesión en Vuex
      //router.push('/login'); // Redirige al login
    }
    return Promise.reject(error); // Propagar el error para manejarlo en los componentes
  }
}
