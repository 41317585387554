<template>
  <div>
    <!-- <v-combobox clearable dense hide-selected outlined v-model="selectedValue" :items="items" item-text="Especialidad"
      item-value="IdEsp" label="¿Cuál especialidad médica?" persistent-hint return-object single-line width="140"
      @change="emitValue"></v-combobox> -->
      <v-combobox
  clearable
  dense
  hide-selected
  outlined
  v-model="selectedValue"
  :items="items"
  item-text="Especialidad"
  item-value="IdEsp"
  label="Selecciona una especialidad médica"
  persistent-hint
  return-object
  single-line
  width="100%"
  @change="emitValue"
  class="my-combobox"
>
</v-combobox>


</div>
</template>

<script>
import EndPointNet from "@/functions/axios/axios-bearer";

export default {
  name: "ComboboxEsp",
  data() {
    return {
      selectedValue: '',
      items: []
    };
  },
  mounted() {
    this.getEspecialidades()
  },
  methods: {
    emitValue(value) {
      console.log(value)
      this.$emit("value-changed", value?.Id);
    },
    getEspecialidades() {
      this.$loading(true);
      EndPointNet(`especialidad`, "GET")
        .then((response) => {
          if (response.status === 200) {
            this.items = response.data;
            this.$loading(false);
          }
        })
        .catch(() => {
          this.$loading(false);
        });
    },
  },
};
</script>

<style scoped>
.my-combobox .v-input__control {
  border-radius: 12px; /* Bordes más redondeados para un estilo moderno */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Sombra suave para profundidad */
  transition: all 0.3s ease-in-out; /* Transición suave */
}

.my-combobox .v-input__control:focus-within {
  border-color: #1976D2; /* Borde azul cuando se enfoca */
  box-shadow: 0 4px 12px rgba(25, 118, 210, 0.3); /* Sombra más pronunciada cuando está enfocado */
}

.my-combobox .v-input__control input {
  font-size: 16px; /* Tamaño de fuente más grande */
  font-weight: 500; /* Negrita para que se vea más destacado */
}

.my-combobox .v-label {
  font-weight: bold; /* Etiqueta en negrita para resaltar */
  color: #1976D2; /* Color llamativo para la etiqueta */
}
</style>