<template>
  <v-container fluid>
    <p class="grey--text text-h5">Listado de cotizaciones realizadas</p>

    <!--filtos-->
    <v-row no-gutters>
      <v-col sm="6" md="6" lg="2">
        <YearPicker v-model="selectedYear" />
      </v-col>
      <v-col sm="2" md="2" lg="3">
        <v-btn color="azul_med" @click.native="onClickFunction" dark elevation="0">Buscar</v-btn>
      </v-col>
      <v-col col="6">

      </v-col>
      <v-col col="6" class="text-end">
        <v-col class="text-h4  azul_med--text">
          <v-list-item-subtitle>No. Recibos</v-list-item-subtitle>
          {{ this.num_recibos }}
        </v-col>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="8">
        <v-btn color="azul_med" @click.native="onCleardate" dark elevation="0">HASTA HOY</v-btn>
      </v-col>
      <v-col cols="4" class="text-end">
        <v-text-field class="col" v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details
          outlined dense @change="comp_change()"></v-text-field>
      </v-col>
    </v-row>
    <v-data-table :group-by="'agente'" :items-per-page="-1" fixed-header calculate-widths :headers="headers"
      :items="pagos" item-key="id" :search="search">
      <template v-slot:[`item.contratante`]="{ item }">
        <div v-if="item.contratante === '' || item.contratante === null">
          <strong>{{ item.nombretitular }}</strong>
        </div>
        <div v-else>
          <small v-if="item.contratante?.replace(/\s+/g, '') !== item.nombretitular?.replace(/\s+/g, '')">CONTRATANTE: {{
          item.contratante }}</small><br />
          <strong>{{ item.nombretitular }}</strong>
        </div>
      </template>
      <template v-slot:[`item.folio`]="{ item }">
        <span class="grey--text">{{ item.idcontrato }}</span>
      </template>
      <template v-slot:[`item.pagado`]="{ item }">
        <div v-if="item.pagado === 0">
          <span style="color: grey;">IMPAGO</span>
        </div>
        <div v-else>
          <strong>PAGADO</strong>
        </div>
      </template>
      <template v-slot:[`item.estado`]="{ item }">
        <span v-if="item.estado === '1'" class="estatus-activo">Activo</span>
        <span v-if="item.estado === '2'" class="estatus-expirado">Suspendida</span>
      </template>
      <template v-slot:[`item.recibo`]="{ item }">
        <span>{{ item.recibo }} de {{ item.recibos }}</span>
      </template>
      <template v-slot:[`item.DatePagoFormat`]="{ item }">
        <span class="creado-style">{{ item.DatePagoFormat }}</span>
      </template>
      <template v-slot:[`item.Diferencia`]="{ item }">
        <span v-if="item.Diferencia <= 15 && item.Diferencia > 0 && item.pagado == 0">{{ item.Diferencia }} días</span>
        <span v-if="item.Diferencia > 15 && item.pagado == 0" class="estatus-retardo">{{ item.Diferencia }} días</span>
        <span v-if="item.Diferencia < 0 && item.pagado == 0" style="color: olivedrab;">A tiempo</span>
      </template>
      <!-- <template v-slot:[`item.idpago`]="{ item }">
          <v-card-actions>
            <v-btn color="blue darken-1" text :href="`https://adm.medicover.mx/admin/instructivo.php?id=${item.idpago}`"
              target="_blank">VER RECiBO</v-btn> |
                          <v-btn class="black--text  text" text to="/carrito" icon>
                Pagar
            </v-btn> |
            <email-recibo-modal v-if="item.pagado=='0'" :ReciboId="item.idpago" :Membresia="item.idcontrato"
              :Recibo="`${item.recibo} de ${item.recibos}`" :Titular="item.nombretitular"
              :Monto="$formatCurrency(item.pago)"></email-recibo-modal>
  
          </v-card-actions>
        </template> -->
      <template v-slot:[`item.pago`]="{ item }">
        <span class="estatus-activo">{{ $formatCurrency(item.pago) }}</span>
      </template>
      <template v-slot:[`item.idpago`]="{ item }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn red icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <!-- <v-list-item @click="editItem(item)">
                <v-list-item-title>Pagar</v-list-item-title>
              </v-list-item>
              <v-list-item @click="deleteItem(item)">
                <v-list-item-title>Registrar pago</v-list-item-title>
              </v-list-item> -->
            <!-- <v-list-item @click="deleteItem(item)">
                <v-list-item-title>Ver pagos</v-list-item-title>
              </v-list-item> -->
            <v-list-item :disabled="!(item.pagado == '0')">
              <email-recibo-modal :ReciboId="item.idpago" :Membresia="item.idcontrato"
                :Recibo="`${item.recibo} de ${item.recibos}`" :Titular="item.nombretitular"
                :Monto="$formatCurrency(item.pago)"></email-recibo-modal>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import ApiBase from '@/functions/axios/axiosNetBase'
import YearPicker from '@/components/AnioComponent.vue';
import EmailReciboModal from '@/components/Pagos/EmailReciboComponent.vue'

export default {
  name: 'InicioAgente',
  components: {
    YearPicker,
    EmailReciboModal
  },
  computed: {
    ...mapGetters(['getCODAGE'])
  },
  mounted() {
    this.getPagosPendientesAgente()
  },
  data: () => ({
    selectedYear: null,// new Date().toISOString().substr(0, 7), // new Date().getFullYear().toString(),
    search: '',
    pagos: [],
    headers: [
      { text: 'Num', value: 'num', width: '5%' },
      { text: 'Contrato', value: 'idcontrato', width: '10%' },
      { text: 'Agente', value: 'agente' },
      //{ text: 'Plan', value: 'Plan' },
      //{ text: 'Tipo pago', value: 'Fraccion' },
      { text: 'Titular', value: 'contratante', width: '20%' },
      //{ text: 'Titular', value: 'nombretitular' },
      { text: 'Fecha pago', value: 'DatePagoFormat', width: '10%' },
      { text: 'recibo', value: 'recibo' },
      { text: 'Retardo', value: 'Diferencia' },
      { text: 'Estatus', value: 'pagado' },
      { text: 'Monto', value: 'pago' },
      { text: 'Opciones', value: 'idpago', sortable: false },
    ],
    num_recibos: 0
  }),
  methods: {
    editItem(item) {
      console.log(item)
    },
    deleteItem(item) {
      console.log(item)
    },
    onCleardate() {
      this.selectedYear = null
      this.getPagosPendientesAgente()
    },
    onClickFunction() {
      this.getPagosPendientesAgente()
    },
    getPagosPendientesAgente() {
      this.$loading(true)
      ApiBase(
        `getCobranza/${this.selectedYear}`,
        'GET'
      ).then((response) => {
        if (response.status === 200) {
          if (response.data) {
            this.$loading(false)
            this.pagos = response.data
            this.num_recibos = this.pagos.length;
          }
        }
      })
        .catch(() => {
          this.$loading(false)
          this.$swal({
            position: '',
            // title: 'Valida',
            //text: 'Se genero un error',
            html: `Ha ocurrido un error inesperado, <b>intenta mas tarde</b> .`,
            icon: 'error',
            confirmButtonText: 'Cerrar',
            showCloseButton: true,
            showConfirmButton: true,
            //timer: 1500,
          })
        });
    }
  }
}
</script>
<style scoped>
.creado-style {
  font-weight: bold;
  color: #696969;
}
</style>